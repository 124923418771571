@import '../../styles/fonts.scss';

.wrapper {
  width: 100%;
  display: flex;
  height: 290px;
  overflow: hidden;
  padding: 14px 17px;
  position: relative;
  align-items: flex-end;
  background-size: cover;
  flex-direction: column;
  background-position: center;
  justify-content: flex-start;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px 0 #000000;

  &::before {
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
  }

  &__image {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.5s;
  }

  &:hover {
    & .wrapper__image {
      transform: scale(1.1);
    }
  }

  &__title {
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    position: relative;
    font-family: $poppinsFontFamily;
    text-shadow: 1px 1px 1px #000000;
  }

  &__date {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    position: relative;
    text-shadow: 1px 1px 1px#000000;
    font-family: $poppinsFontFamily;
  }
}
