.card {
  width: 100%;
  display: flex;
  max-width: 400px;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  padding: 41px 20px 48px;
  justify-content: flex-start;
  box-shadow: 0px 0px 4px 2px rgba(143, 177, 106, 1);

  &:hover {
    transform: scale(1.02);
    transition: transform 0.5s;

    @media (max-width: 768px) {
      transform: scale(1);
    }
  }

  &__icon {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    cursor: inherit;

    -webkit-animation: rotate 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation: rotateBack 0.5s;
    animation-fill-mode: forwards;
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
    color: #20272f;
    line-height: 27px;
    text-align: center;
    //margin-bottom: 62px;
    font-family: Poppins;
  }

  &__description {
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    text-align: center;
    font-family: Poppins;
  }
}

.card:hover .card__icon {
  -webkit-animation-fill-mode: forwards;
  animation: rotate 0.5s;
  animation-fill-mode: forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateBack {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
