@import '../../../styles/fonts.scss';

.wrapper {
  position: relative;
  align-items: center;
  padding: 230px 0 140px;

  @media (max-width: 768px) {
    padding-top: 770px;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1024px) {
      align-items: center;
    }

    &_info {
      flex: 1;
      gap: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      &_title {
        font-family: $poppinsFontFamily;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        color: #ffffff;

        @media (max-width: 445px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      &_text {
        font-family: $poppinsFontFamily;
        font-weight: 800;
        font-size: 40px;
        line-height: 60px;
        color: #8fb16a;

        @media (max-width: 445px) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      &_description {
        font-family: $poppinsFontFamily;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        line-height: 27px;
        width: max-content;

        @media (max-width: 445px) {
          font-size: 16px !important;
          line-height: 20px;
        }
      }

      &_btn {
        background: transparent;
        border: 1px solid #8fb16a;
        padding: 12px 36px;
        color: #8fb16a;
        text-transform: uppercase;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        transition: all 0.5s;
      }
    }

    &_img {
      //flex: 1;
      width: 50%;
      border-radius: 20px;
      overflow: hidden;
      margin-left: 30px;

      img {
        width: 100%;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
}
