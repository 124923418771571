.top_part {
  background-image: url(../../assets/images/ServicesBgImage.png);
  background-size: cover;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: 0;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    height: 80vh;
  }

  &__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);

    @media (min-width: 1024px) {
      height: 80vh;
    }
  }

  &__title {
    font-family: 'Roboto', sans-serif;
    margin-top: 32px;
    font-size: 120px;
    font-weight: 900;
    line-height: 140px;
    color: transparent;
    text-align: right;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 1);

    @media (max-width: 1275px) {
      font-size: 100px;
      line-height: 120px;
    }

    @media (max-width: 1024px) {
      font-size: 80px;
      line-height: 92px;
    }

    @media (max-width: 890px) {
      font-size: 64px;
      line-height: 72px;
    }

    @media (max-width: 700px) {
      font-size: 48px;
      line-height: 64px;
    }

    @media (max-width: 530px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (max-width: 425px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__sub_title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: right;
    color: #fff;
    margin-bottom: 16px;

    @media (max-width: 890px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 700px) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: 425px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__description {
    font-weight: 400;
    padding-left: 32%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 24px;
    line-height: 36px;
    text-align: right;
    color: #fbfcfb;
    font-family: Poppins;

    @media (max-width: 768px) {
      padding-left: 0;
      line-height: 26px;
    }

    @media (max-width: 530px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
