.block {
  padding-bottom: 60px;

  &__title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    margin: 59px 0 96px;
    color: #20272f;
    //background: -webkit-linear-gradient(90deg, #20272f 0%, #6b7c58 100%);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
