@import '../../../styles/fonts.scss';

.servicesContainer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;

  background-color: #20272f;

  @media (max-width: 550px) {
    padding-top: 60px;
  }
}

.service {
  &__wrapper {
    display: flex;
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 20px 3px #000000;
    padding-bottom: 127px;

    @media (max-width: 1024px) {
      padding: 40px 50px 127px 50px;
    }

    @media (max-width: 425px) {
      padding: 10px 50px 127px 50px;
    }

    & .title__wrapper {
      display: flex;
      padding-top: 40px;
      align-items: center;
      justify-content: space-between;

      .service__title {
        max-width: min-content;
        position: relative;
        display: flex;
        align-items: center;

        &_icon {
          width: 127px;
          height: 127px;
          display: flex;
          align-items: center;

          @media (max-width: 1512px) {
            width: 108px;
            height: 108px;
          }

          @media (max-width: 1024px) {
            width: 80px;
            height: 80px;
          }

          @media (max-width: 550px) {
            display: none;
          }
        }

        &_services {
          font-family: $robotoFontFamily;
          font-size: 120px;
          font-weight: 900;
          line-height: 140px;
          text-align: left;
          color: #ffffff;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #8fb16a;

          @media (max-width: 1512px) {
            font-size: 80px;
            line-height: 88px;
          }

          @media (max-width: 1024px) {
            font-size: 64px;
            line-height: 70px;
          }
          @media (max-width: 768px) {
            font-size: 48px;
            line-height: 64px;
          }

          @media (max-width: 619px) {
            font-size: 36px;
            line-height: 48px;
          }

          @media (max-width: 435px) {
            font-size: 28px;
            line-height: 32px;
          }

          @media (max-width: 320px) {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }

    & .service__item__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 3%;
      justify-content: space-between;

      & .service__item__icon__container {
        width: 140px;
        height: 140px;

        & .service__item__icon {
          width: 100%;
          height: 100%;
        }

        @media (max-width: 1024px) {
          width: 120px;
          height: 120px;
        }

        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
        }

        @media (max-width: 425px) {
          width: 80px;
          height: 80px;
        }
      }
      & .service__item {
        max-width: 420px;
        margin-top: 100px;
        width: 30%;
        @media (max-width: 1024px) {
          width: 44%;
        }

        @media (max-width: 430px) {
          width: 100%;
        }

        & .service__item__info__title {
          font-family: $poppinsFontFamily;
          font-size: 24px;
          font-weight: 900;
          line-height: 36px;
          text-align: left;
          width: 100%;
          margin-top: 20px;

          @media (max-width: 1024px) {
            font-size: 20px;
            line-height: 24px;
          }

          @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        & .service__item__info__description {
          font-family: $poppinsFontFamily;
          font-size: 20px;
          font-weight: 275;
          line-height: 24px;
          text-align: left;
          margin-top: 20px;

          @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 24px;
          }

          @media (max-width: 425px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    & .service__button__wrapper {
      width: 100%;
      display: flex;
      margin-top: 50px;
      margin-bottom: 60px;
      justify-content: center;

      & .service__button {
        border-radius: 10px;
        font-family: $poppinsFontFamily;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        color: #fff;
        background-color: #20272f;
        padding: 10px 100px;

        @media (max-width: 1024px) {
          font-size: 22px;
          line-height: 24px;
          padding: 10px 80px;
        }

        @media (max-width: 420px) {
          font-size: 20px;
          line-height: 24px;
          padding: 10px 50px;
        }
      }
    }
  }
}
