@import '../../styles/fonts.scss';

.card {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  overflow: hidden;
  max-width: 343px;
  min-width: 343px;
  font-family: $poppinsFontFamily;
  margin: 10px 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  @media (max-width: 1024px) {
    max-width: 300px;
    min-width: 300px;
  }

  @media (max-width: 1024px) {
    max-width: 300px;
    min-width: 300px;
  }

  @media (max-width: 430px) {
    max-width: calc(100% - 20px);
    min-width: 100px;
  }

  &__img {
    width: 100%;
    height: 244px;
    border-radius: 20px 20px 0 0;
    pointer-events: none;
  }

  &_description {
    gap: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px 18px 18px;
    background-color: #20272f;

    &_head {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &_title {
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
      }

      &_info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &_location {
          gap: 6px;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 18px;

          svg {
            width: 14px;
            width: 14px;
          }
        }
      }
    }

    &_text {
      height: 100px;
      font-size: 16px;
      overflow: hidden;
      line-height: 24px;
      word-break: break-all;
    }

    &_button {
      display: flex;
      justify-content: flex-end;

      &_btn {
        color: #fff;
        background-color: transparent;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        gap: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}
