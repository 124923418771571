.tab__wrapper {
  width: 100%;
  display: flex;
  padding-top: 34px;
  margin-bottom: 54px;
  justify-content: center;
}

.list__wrapper {
  margin-bottom: 50px;
}
