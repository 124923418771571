.wrapper {
  &_info {
    max-width: 40%;
    background-color: #fff;
    z-index: 4;

    @media (max-width: 1220px) {
      max-width: 30%;
    }

    @media (max-width: 920px) {
      max-width: 70%;
      margin: 0 auto;
    }

    @media (max-width: 920px) {
      max-width: 85%;
    }

    &_sub_title {
      font-family: Poppins;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 24px;

      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }

    &_sub_description {
      font-family: Poppins;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 14px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: circle;
        & p {
          text-align: left !important;
        }
      }
    }
  }

  &_top {
    margin-top: 90px;
    margin-bottom: 63px;
    display: flex;
    justify-content: space-between;
    gap: 11%;

    @media (max-width: 920px) {
      flex-direction: column;
      gap: 240px;
      justify-content: center;
    }

    @media (max-width: 560px) {
      gap: 140px;
    }

    &__left {
      position: relative;
      max-width: 56%;
      width: 100%;
      height: min-content;

      @media (max-width: 1260px) {
        max-width: 50%;
      }

      @media (max-width: 1024px) {
        max-width: 46%;
      }

      @media (max-width: 920px) {
        max-width: 100%;
      }

      &__1 {
        max-width: 100%;
        width: 100%;
        z-index: 3;
        position: relative;

        @media (max-width: 920px) {
          max-width: 70%;
        }
      }

      &__2 {
        bottom: -58px;
        right: -113px;
        max-width: 322px;
        width: 100%;
        width: 100%;
        z-index: 4;
        position: absolute;

        @media (max-width: 1024px) {
          right: -10%;
          bottom: -10%;
          max-width: 60%;
        }

        @media (max-width: 920px) {
          max-width: 40%;
          right: 20%;
          bottom: -18%;
        }
      }

      &__3 {
        position: absolute;
        bottom: 91px;
        right: -166px;
        max-width: 210px;
        z-index: 2;
        width: 100%;
        height: 210px;

        @media (max-width: 920px) {
          max-width: 30%;
          height: unset;
          right: 10%;
          bottom: 16%;
        }
      }

      &__4 {
        position: absolute;
        z-index: 1;
        max-width: 130px;
        width: 100%;
        height: 130px;
        right: 183px;
        bottom: -104px;

        @media (max-width: 920px) {
          max-width: 30%;
          height: unset;
          bottom: -32%;
          right: 42%;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 35.41%;
      width: 100%;
      background: white;
      z-index: 5;
      max-width: 40%;

      @media (max-width: 1024px) {
        max-width: 40%;
      }

      @media (max-width: 920px) {
        max-width: 100%;
      }
    }
  }

  &_center {
    display: flex;
    justify-content: space-between;
    gap: 8.52%;
    align-items: flex-start;
    margin-top: 110px;
    margin-bottom: 92px;

    @media (max-width: 1220px) {
      justify-content: center;
    }

    @media (max-width: 920px) {
      gap: 24px;
      align-items: center;
      flex-direction: column-reverse;
    }

    &_image {
      max-width: 56%;
      object-fit: contain;

      @media (max-width: 920px) {
        max-width: 100%;
      }
    }
  }

  &_bottom {
    display: flex;
    justify-content: center;
    gap: 4%;
    margin-top: 110px;
    margin-bottom: 92px;

    &_card {
      max-width: 29%;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    @media (max-width: 768px) {
      gap: 70px;
      flex-direction: column;
      justify-content: center;
    }
  }
}
