@import '../../styles/fonts.scss';

.wrapper {
  padding: 100px 0;

  &__title {
    color: #20272f;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 8px;
    font-family: $poppinsFontFamily;
  }

  &__subtitle {
    color: #20272f;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 40px;
    font-family: $poppinsFontFamily;
  }

  &__button {
    margin-top: 50px;
    border-radius: 10px;
    font-family: $poppinsFontFamily;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background-color: #20272f;
    padding: 10px 100px;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  & label {
    color: #20272f;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    font-family: $poppinsFontFamily;
  }

  & input,
  textArea {
    width: 100%;
    min-height: 50px;
    margin-top: 16px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 10px;
    border: 1px solid #20272f;
    font-family: $poppinsFontFamily;
  }

  & textArea {
    padding: 20px;
  }
}
