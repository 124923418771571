@import '../../../styles/fonts.scss';

.activityContainer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #20272f;
}

.activity {
  border-radius: 20px;
  padding-top: 65px;
  padding-bottom: 120px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px #000000;

  @media (max-width: 768px) {
    padding-bottom: 65px;
  }
}

.head {
  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    @media (max-width: 550px) {
      justify-content: flex-end;
    }

    &_title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_icon {
        width: 127px;
        height: 127px;
        display: flex;
        align-items: center;

        @media (max-width: 1512px) {
          width: 80px;
          height: 80px;
        }

        @media (max-width: 1024px) {
          width: 60px;
          height: 60px;
        }

        @media (max-width: 550px) {
          display: none;
        }
      }

      &_services {
        font-family: $robotoFontFamily;
        font-size: 120px;
        font-weight: 900;
        line-height: 140px;
        text-align: left;
        color: #ffffff;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #8fb16a;

        @media (max-width: 1512px) {
          font-size: 80px;
          line-height: 88px;
        }

        @media (max-width: 1024px) {
          font-size: 64px;
          line-height: 70px;
        }
        @media (max-width: 768px) {
          font-size: 48px;
          line-height: 64px;
        }

        @media (max-width: 619px) {
          font-size: 36px;
          line-height: 48px;
        }

        @media (max-width: 435px) {
          font-size: 28px;
          line-height: 32px;
        }

        @media (max-width: 320px) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  &__description {
    max-width: 100%;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-left: 16%;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    &__list {
      display: flex;
      margin-top: 16px;
      margin-bottom: 85px;
      flex-direction: column;
      align-items: flex-end;
      list-style-type: circle;
    }
  }
}

.carousel {
  &__container {
    margin: 0;
    gap: 0;
    padding-bottom: 70px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
