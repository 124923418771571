@import '../../../styles/fonts.scss';

.wrapper {
  &__statistics {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -95px;
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: #e7e9ed;
    max-width: calc(100% - 64px);
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px 3px #00000080;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &_item {
      width: 25%;
      text-align: center;
      padding: 31px 57px;
      display: flex;
      flex-direction: column;
      background-color: #e7e9ed;

      @media (max-width: 1024px) {
        padding: 31px 0;
      }
      @media (max-width: 786px) {
        width: 90%;
      }

      &__number {
        font-size: 60px;
        font-weight: 700;
        line-height: 90px;
        text-align: center;
        color: #000;

        @media (max-width: 1024px) and (min-width: 768px) {
          font-size: 36px;
        }
      }

      &_description {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 1024px) and (min-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &_last {
        border-radius: 10px 0 0 10px;
      }

      &_second {
        border-radius: 0 10px 10px 0;
      }
    }

    &__vertical_line {
      width: 1px;
      height: 100px;
      border-radius: 18px;
      border: 0.5px solid #1e242c80;

      @media (max-width: 1024px) {
        height: 150px;
      }

      @media (max-width: 768px) {
        height: 1px;
        width: 80%;
      }
    }
  }
}
