.wrapper {
  // max-width: 29%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  // @media (max-width: 768px) {
  //   max-width: 100%;
  // }

  &__name {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 20px;
      font-weight: 700;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 900;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
}
