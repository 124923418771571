@import '../../../styles/fonts.scss';

.container {
  width: calc(100% - 128px) !important;
  left: 0;
  right: 0;
  z-index: 1;
  height: 520px;
  bottom: -30px;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  padding: 0 !important;
  border-radius: 10px;
  background-color: #20272f;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.4);

  @media (min-width: 1598px) {
    width: calc(100% - 200px) !important;
  }

  @media (max-width: 1024px) {
    height: 350px !important;
    padding: 0 50px;
  }

  @media (max-width: 550px) {
    width: calc(100% - 28px) !important;
  }

  @media (max-width: 425px) {
    height: 450px !important;
  }
  @media (max-width: 320px) {
    height: 520px !important;
  }
}

.carousel {
  &__item {
    display: flex;
    flex-direction: row;

    &__info {
      &__container {
        display: flex;
        width: 50%;
        padding: 0 76px;
        flex-direction: column;
        justify-content: center;
        background-color: #20272f;
        border-radius: 10px;

        @media (max-width: 1024px) {
          height: 350px !important;
        }

        @media (max-width: 550px) {
          padding: 0 41px;
        }

        @media (max-width: 425px) {
          height: 450px !important;
        }

        @media (max-width: 320px) {
          height: 520px !important;
        }

        @media (max-width: 768px) {
          width: 100%;
          align-items: center;
        }
      }

      &__title {
        font-family: $poppinsFontFamily;
        font-size: 72px;
        font-weight: 400;
        line-height: 108px;
        text-align: left;
        color: #ffffff;

        @media (max-width: 1440px) {
          font-size: 48px;
          line-height: 64px;
        }

        @media (max-width: 1024px) {
          font-size: 28px;
          line-height: 32px;
          text-align: center;
        }
      }

      &__description {
        color: #ffffff;
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
        text-align: left;
        margin-top: 8px;
        font-family: $poppinsFontFamily;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 24px;
        }

        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
      }

      &__button {
        border: 1px solid;
        padding: 10px;
        margin-top: 28px;
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg, #ffffff 0%, #20272f 100%);
        font-family: $robotoFontFamily;
        font-size: 24px;
        font-weight: 100;
        line-height: 28.13px;
        text-align: center;
        background-color: transparent;
        align-self: flex-start;
        color: #ffffff;

        @media (max-width: 1024px) {
          align-self: center;
          padding: 5px 10px;
          font-size: 18px;
        }
      }
    }

    &__image {
      width: 50%;
      display: flex;

      &_item {
        width: 100%;
        height: 520px;
        border-radius: 0 10px 10px 0;

        @media (max-width: 1024px) {
          height: 350px !important;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
