.language {
  position: relative;
  background-color: #6b7c58;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  text-align: center;
  color: #fff;
  max-width: 113px;
  width: 100%;
  cursor: pointer;

  &__list {
    position: absolute;
    gap: 8px;
    background-color: #20272f;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0;
    left: 0;
    right: 0;
    top: 50px;
    text-align: center;
    color: #fff;
    max-width: 113px;
    width: 100%;
    border: 2px solid #6b7c58;
    cursor: default;
    display: flex;
    flex-direction: column;

    &_item {
      cursor: pointer;
      background-color: #6b7c58;
    }
  }
}
