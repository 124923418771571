@import './fonts.scss';
@import './local.scss';
@import './libs.scss';

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1784px;
}

.alternative_container {
  max-width: 1820px;
}

.image_container {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.bg_dark {
  width: 100%;
  background-color: #20272f;
  margin: 0 auto;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}
.react-multi-carousel-dot button {
  border: 0;
  background-color: #20272f;
}

.react-multi-carousel-dot--active button {
  background-color: #8fb16a;
}

.ant-tabs {
  &-nav {
    &::before {
      border: 0 !important;
      height: 1px !important;
      background-color: #6b7c58 !important;
    }
  }
  &-ink-bar {
    height: 4px !important;
    background-color: #6b7c58 !important;
  }

  &-tab {
    margin: 0 !important;
    padding: 16px 48px !important;

    font-size: 32px !important;
    line-height: 48px !important;

    @media (max-width: 1512px) {
      font-size: 24px !important;
      line-height: 28px !important;
      padding: 16px 24px !important;
    }

    @media (max-width: 640px) {
      font-size: 18px !important;
      line-height: 24px !important;
      padding: 8px 16px !important;
    }

    @media (max-width: 425px) {
      font-size: 14px !important;
      line-height: 18px !important;
      padding: 4px 8px !important;
    }
    @media (max-width: 320px) {
      font-size: 10px !important;
      line-height: 12px !important;
      padding: 8px 10px !important;
    }

    & .ant-tabs-tab-btn {
      color: rgba(32, 39, 47, 0.75) !important;
    }

    &:hover {
      & .ant-tabs-tab-btn {
        color: #6b7c58 !important;
      }
    }

    &-active {
      & .ant-tabs-tab-btn {
        color: #6b7c58 !important;
      }
    }
  }
}
