.wrapper {
  width: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  &__label {
    font-size: 14px;
    color: #8793a0;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    box-sizing: border-box;
  }

  &_error_part {
    position: relative;

    &_icon {
      display: block;
    }

    &_text {
      top: 0;
      left: 84px;
      display: none;
      font-size: 12px;
      margin-top: 4px;
      color: #ed5151;
      line-height: 16px;
      position: absolute;
      min-width: max-content;
      transform: translateX(-50%);
    }
  }

  &__icon {
    top: 50%;
    left: 12px;
    left: 16px;
    width: 16px;
    width: 20px;
    height: 20px;
    height: 16px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__input {
    width: 100%;
    height: 48px;
    outline: none;
    font-size: 18px;
    appearance: none;
    font-weight: 300;
    line-height: 24px;
    border-radius: 8px;
    transition: 0.6s all;
    box-sizing: border-box;
    padding: 0 12px 0 16px;
    color: #e6f4ff !important;
    background-color: #142837;
    border: 1px solid transparent;

    &::placeholder {
      color: #6f7d8c;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      transform: scale(1.05);
      border-color: #6f7d8c;
    }

    &:focus {
      border-color: #0068af;
      background-color: #0f1e29;
    }

    &:focus-within + .wrapper__box__container {
      background-color: #0f1e29;
    }

    &__icon {
      padding-left: 44px;
    }

    &__error {
      border-color: #ed5151 !important;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    background: #142837;

    &__container {
      gap: 4px;
      top: 50%;
      right: 8px;
      display: flex;
      padding-left: 4px;
      position: absolute;
      align-items: center;
      transition: 0.6s all;
      justify-content: center;
      transform: translateY(-50%);
      background-color: #142837;
    }

    &__eye {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.wrapper_error_part_icon:hover + .wrapper_error_part_text {
  display: block;
}
