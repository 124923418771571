@import 'react-multi-carousel/lib/styles.css';

.slick-arrow.slick-prev {
  width: 32px;
  height: 32px;
}

.slick-arrow.slick-next {
  width: 32px;
  height: 32px;
}

.ant-carousel {
  z-index: 11;
}
