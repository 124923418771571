@import '../../styles/fonts';

.container {
  height: 500px;

  &__item {
    width: 100vw;
    left: -32px;
    display: flex;
    max-width: 1784px;
    position: absolute;
    justify-content: center;
  }
}

.wrapper {
  width: 100vw;
  height: 400px;
  display: flex;
  margin-top: 45px;
  position: relative;
  padding-right: 30px;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  &::after {
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
  }

  &__text {
    z-index: 1;
    width: 100%;
    color: #fff;
    font-family: $poppinsFontFamily;

    text-align: right;
    position: relative;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-top: 40px;
    line-height: 48px;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 429px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__description {
    max-width: 60%;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;

    @media (max-width: 1024px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
